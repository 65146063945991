import SetCookieParser from 'set-cookie-parser';

export default function({$axios, $cookies, i18n, store}) {
  $axios.onRequest((config) => {
    try {
      if (!config.headers.common.Authorization && $cookies.get('sessionid')) {
        config.headers.common.Authorization = `Session ${$cookies.get('sessionid')}`;
      }
    } catch (e) {}
    if (config?.count_loading) {
      store.dispatch('addLoading');
    }
    config.headers.common['Accept-Language'] = i18n.locale;
    return config;
  });

  $axios.onResponse((response) => {
    const cookies = SetCookieParser.parse(response);
    cookies.forEach((cookie) => {
      const {name, value, ...options} = cookie;
      $cookies.set(name, value, options);
    });
    if (response?.config?.count_loading) {
      store.dispatch('removeLoading');
    }
    return response;
  });

  $axios.onError((error) => {
    if (error?.config?.count_loading) {
      store.dispatch('removeLoading');
    }
    return Promise.reject(error);
  });
}
