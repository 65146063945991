import locale77fcde9e from '../../locale/ru.js'
import locale77427e78 from '../../locale/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"trailingSlash":true,"fallbackLocale":"ru","silentTranslationWarn":true},
  vueI18nLoader: true,
  locales: [{"code":"en","file":"en.js"},{"code":"ru","file":"ru.js"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: false,
  langDir: "locale",
  rootRedirect: {"trailingSlash":true,"statusCode":301,"path":"ru"},
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  trailingSlash: true,
  normalizedLocales: [{"code":"en","file":"en.js"},{"code":"ru","file":"ru.js"}],
  localeCodes: ["en","ru"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "o",
  15: "c",
  16: "a",
  17: "l",
  18: "e",
  19: "/",
  20: "e",
  21: "n",
  22: ".",
  23: "j",
  24: "s",
  25: "\"",
  26: ",",
  27: "\"",
  28: "r",
  29: "u",
  30: ".",
  31: "j",
  32: "s",
  33: "\"",
  34: ":",
  35: "\"",
  36: ".",
  37: ".",
  38: "/",
  39: "l",
  40: "o",
  41: "c",
  42: "a",
  43: "l",
  44: "e",
  45: "/",
  46: "r",
  47: "u",
  48: ".",
  49: "j",
  50: "s",
  51: "\"",
  52: "}",
}

export const localeMessages = {
  'ru.js': () => Promise.resolve(locale77fcde9e),
  'en.js': () => Promise.resolve(locale77427e78),
}
