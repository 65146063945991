import Model from './base';

export class VehicleType extends Model {
  resource() {
    return 'catalog/vehicle-type';
  }

  defaultAttributes() {
    return {
      code: null,
      name: null,
      icon_class: null,
    }
  }
}
