import Vue from 'vue';
import moment from 'moment';

const _fractionPrecision = 2;

moment.locale('ru');

Vue.filter(
  'baseUrl',
  (value) => process.env.BASE_CLIENT_URL + value
);

Vue.filter(
  'formatDate',
  (value) => value ? moment(String(value)).format('DD.MM.YY') : null,
);

Vue.filter(
  'shipmentDate',
  (value, locale) => moment().add(value ?? 0, 'days').locale(locale).format('LL'),
);

Vue.filter(
  'formatDateTime',
  (value) => value ? moment(String(value)).format('DD.MM.YY HH:mm') : null,
);

Vue.filter(
  'formatDateNews',
  (value) => value ? moment(String(value)).format('DD/MM/YY') : null,
);

Vue.filter(
  'integerPart',
  (value) => value
    ? [...String(Math.trunc(value))].reverse().join('').match(/.{1,3}/g)
      .map(x => [...x].reverse().join('')).reverse().join(' ')
    : 0,
);

Vue.filter(
  'fractionalPart',
  (value) => value % 1
    ? '.' + String(Math.round((value % 1) * Math.pow(10, _fractionPrecision))).padStart(_fractionPrecision, '0')
    : null,
);

Vue.filter(
  'formatDecimal',
  (value) => value ? value.toFixed(_fractionPrecision) : 0
);


/**
 * Возвращает единицу измерения с правильным окончанием
 *
 * @param {Number} num      Число
 * @param {Object} cases    Варианты слова ['час', 'часа', 'часов']
 * @return {String}
 */
Vue.filter(
  'ending',
  (num, cases, locale) => {
    num = Math.abs(num);
    var word = '';
    if (num.toString().indexOf('.') > -1) {
      word = cases[1];
    } else {
      if (locale === 'ru') {
        word = (
          num % 10 === 1 && num % 100 !== 11
            ? cases[0]
            : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
              ? cases[1]
              : cases[2]
        );
      } else if (locale === 'en') {
        word = (num === 1 ? cases[0] : cases[1]);
      }
    }
    return word;
  }
);
