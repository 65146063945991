import { createYmapsOptions, VueYandexMaps, initYmaps } from 'vue-yandex-maps';

const vueYandexMapsPlugin = async (context) => {
  createYmapsOptions({
  "apikey": "b08fb049-45a3-4cbf-809a-5669bf5f0e02"
});
  if(process.client && VueYandexMaps.settings.value.initializeOn === 'onPluginInit') {
    initYmaps().catch(console.error);
  }
}

export default vueYandexMapsPlugin;
