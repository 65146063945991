// базовый
export const seoMixin = {
  head() {
    const title = this.seoTitle;
    if (title && this.dbConfig) {
      let headObj = {
        title: title,
        meta: [{
          name: 'description',
          hid: 'description',
          content: this.seoDescription,
        }]
      }
      return this.extendHead(headObj);
    }
  },
  mounted() {
    this.$store.dispatch('getScripts');
  },
  computed: {
    seoTitle() {
      return '';
    },
    seoDescription() {
      return '';
    },
    schemaData() {
      // возвращает контент ld+json
      return {
        "@context" : "http://schema.org",
        "url": process.env.BASE_CLIENT_URL,
        "image": `${process.env.BASE_CLIENT_URL}/${process.env.VENDOR}-logo.svg`,
        "@type" : "LocalBusiness",
        "name": this.dbConfig.company_name,
        "telephone": this.dbConfig.company_phone,
        "address": this.dbConfig.company_address,
      }
    },
  },
  methods: {
    extendHead(headObj) {
      // add canonical link
      if (parseInt(this.$route.query.page || 1) > 1) {
        let url = process.env.BASE_CLIENT_URL + this.$route.path;
        headObj.link = [{ rel: 'canonical', href: url }];
      }
      this.getScripts(headObj);
      return headObj;
    },
    getScripts(headObj) {
      headObj.script = [];

      const ldjson = this.schemaData;
      if (ldjson) {
         headObj.script.push({type: 'application/ld+json', json: ldjson});
      }
      let filter = {
        active: true,
        show_on_desktop: true,
      };
      // if (this.$device.isMobile) {
      //   filter.show_on_phone = true;
      // } else if (this.$device.isTablet) {
      //   filter.show_on_tablet = true;
      // } else if (this.$device.isDesktop) {
      //   filter.show_on_desktop = true;
      // }
      let scripts = this.$store.getters.scripts.filter(function (item) {
        for (let key in filter) {
          if (item[key] === undefined || item[key] !== filter[key])
            return false;
        }
        return true;
      });
      for (let script of scripts) {
        if (script.doc_position === 'head') {
          if (script.src) {
            headObj.script.push({type: 'text/javascript', async: !!script.async_script, src: script.src});
            headObj.script.push({type: 'text/javascript', innerHTML: script.text});
          } else {
            headObj.script.push({type: 'text/javascript', async: !!script.async_script, innerHTML: script.text});
          }
        } else if (script.doc_position === 'doc_beg') {
          if (script.src) {
            headObj.script.push({type: 'text/javascript', async: !!script.async_script, src: script.src, body: true});
            headObj.script.push({type: 'text/javascript', innerHTML: script.text, body: true});
          } else {
            headObj.script.push({type: 'text/javascript', async: !!script.async_script, innerHTML: script.text, body: true});
          }
        } else if (script.doc_position === 'doc_end') {
          if (script.src) {
            headObj.script.push({type: 'text/javascript', async: !!script.async_script, src: script.src, body: false});
            headObj.script.push({type: 'text/javascript', innerHTML: script.text, body: false});
          } else {
            headObj.script.push({type: 'text/javascript', async: !!script.async_script, innerHTML: script.text, body: false});
          }
        }
      }
      return headObj;
    },
  },
}

// для страниц
export const headPageMixin = {
  mixins: [seoMixin],
  computed: {
    pageTitleLocaleData() {
      return this.page.page_title_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    pageSeoLocaleData() {
      return this.page.page_seo_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    dbConfigSeoPageLocaleData() {
      return this.dbConfig.seo_page_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    seoTitle() {
      if (!this.page || !this.page.title) {
        return '';
      }
      let title = this.dbConfigSeoPageLocaleData.seo_title_page;
      let localeSeoTitle = this.pageSeoLocaleData.seo_title || this.page.meta?.seo_title;
      let localeTitle = this.pageTitleLocaleData.title || this.page.title;
      title = String(title || '');
      if (title && title.includes('{name}')) {
        title = title.replaceAll('{name}', (localeTitle || ''));
      }
      return localeSeoTitle || title || localeTitle || '';
    },
    seoDescription() {
      let descr = this.dbConfigSeoPageLocaleData.seo_descr_page;
      let localeDescr = this.pageSeoLocaleData.search_description || this.page.meta?.search_description;
      let localeTitle = this.pageTitleLocaleData.title || this.page.title;
      descr = String(descr || '').replaceAll('{name}', (localeTitle || ''));
      return localeDescr || descr || '';
    },
  },
}

// для категорий
export const headCatalogMixin = {
  mixins: [seoMixin],
  computed: {
    categoryLocaleData() {
      return this.category?.category_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    categorySeoLocaleData() {
      return this.seo?.category_seo?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    dbConfigSeoCategoryLocaleData() {
      return this.dbConfig.seo_category_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    seoTitle() {
      let name = this.categoryLocaleData.name;
      if (!this.category || !name) {
        return '';
      }
      if (this.metaTitleSuffix) {
        name += this.metaTitleSuffix;
      }
      let title = this.dbConfigSeoCategoryLocaleData.seo_title_category;
      title = String(title || '').replaceAll('{name}', (name || ''));
      return this.categorySeoLocaleData.seo_title || title || name;
    },
    seoDescription() {
      let descr = this.dbConfigSeoCategoryLocaleData.seo_descr_category;
      descr = String(descr || '').replaceAll('{name}', (this.categoryLocaleData.name || ''));
      return this.categorySeoLocaleData.seo_description || descr || '';
    },
  },
}


export const headCatalogOverviewMixin = {
  mixins: [headCatalogMixin],
  computed: {
    seoTitle() {
      let name = this.categoryLocaleData.name;
      if (!this.category || !name) {
        return '';
      }
      if (this.metaTitleSuffix) {
        name += this.metaTitleSuffix;
      }
      let title = this.dbConfigSeoCategoryLocaleData.seo_title_category;
      title = String(title || '').replaceAll('{name}', (name || ''));
      return this.categorySeoLocaleData.seo_title_overview ||
             this.categorySeoLocaleData.seo_title ||
             title ||
             name;
    },
    seoDescription() {
      let descr = this.dbConfigSeoCategoryLocaleData.seo_descr_category;
      descr = String(descr || '').replaceAll('{name}', (this.categoryLocaleData.name || ''));
      return this.categorySeoLocaleData.seo_description_overview ||
             this.categorySeoLocaleData.seo_description ||
             descr ||
             '';
    },
  },
}

// для продуктов
export const headProductMixin = {
  mixins: [seoMixin],
  methods:{
    getPropertyLocaleName(prop) {
      let data = prop?.property_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
      return data.name || prop.name;
    },
    getPropertyValueLocaleData(prop) {
      return this.product.product_properties?.find(
        x => x.property === prop.id && x.value && (!x.language || x.language.slug === this.$i18n.locale)
      ) || {};
    },
    getPropValues(prop_name) {
      // const prop = this.displayedProperties.filter(prop => this.getPropertyLocaleName(prop) === prop_name)
      const prop = this.productProperties.filter(prop => this.getPropertyLocaleName(prop) === prop_name)
      if (prop?.length) {
        if (this.getPropertyValueLocaleData(prop[0]).multiple_values)
          return this.getPropertyValueLocaleData(prop[0]).multiple_values
        else
          return this.getPropertyValueLocaleData(prop[0]).value
      }
      return ''
    },
  },
  computed: {
    propModel() {
      let res = this.getPropValues('Модель двигателя')
      if (res) {
        if (Array.isArray(res))
          res = res.slice(0, 2).join(', ')
      }
      return res
    },
    propOE() {
      let res = this.getPropValues('OE-номер')
      if (res) {
        if (Array.isArray(res))
          res = res.join(', ')
      }
      return res
    },
    propMaker() {
      let res = this.getPropValues('Производитель двигателя')
      if (res) {
        if (Array.isArray(res))
          res = res[0]
      }
      return res
    },
    productLocaleData() {
      return this.product?.product_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    productSeoLocaleData() {
      return this.product?.product_seo_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    dbConfigSeoProductLocaleData() {
      return this.dbConfig.seo_product_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    seoTitle() {
      if (!this.product || !this.productLocaleData.name) {
        return '';
      }
      let title = this.dbConfigSeoProductLocaleData.seo_title_product;
      const vendor_name_clear = this.productLocaleData.name.split('(')[0]
      let vendor_oe_number = ''
      let maker = ''
      let model = ''
      let vehicle_type_name = ''
      if (this.$i18n.locale === 'ru') {
        if (process.env.VENDOR === 'wetz'){
          this.productLocaleData.name.indexOf(';') > -1 ? this.productLocaleData.name.split(';')[1].trim() || '' : ''
          vendor_oe_number = vendor_oe_number.replaceAll(')', '')
          maker = this.propMaker
          model = this.propModel
        }
        else if (process.env.VENDOR === 'wellfar') {
          const oems = this.getPropValues('OE-номер')
          if (oems?.length)
            vendor_oe_number = oems[0]
          const vehicle_types = this.getPropValues('Тип ТС')
          if (vehicle_types?.length === 1) {
            const vt = {
              'cv': 'коммерческой техники',
              'pc': 'легковых автомобилей',
            }
            vehicle_type_name = vt[vehicle_types[0]]
          }
          if (this.product.car_model_details?.length) {
            maker = this.product.car_model_details[0].model.mark.name
            const models = this.product.car_model_details.map(x => x.engine || x.power_kw + ' kW')
            model = models[0]
          }
        }
      }

      if(title){
        if ((maker || model) && (title?.indexOf('{maker}') !== -1 || title?.indexOf('{model}') !== -1)) {
          title = title.replaceAll('{для}', ('для'))
        }
        else {
          title = title.replaceAll('{для}', (''))
        }
      }

      title = String(title || '')
        .replaceAll('{name}', (this.productLocaleData.name || ''))
        .replaceAll('{wetz_name_clear}', (vendor_name_clear || ''))
        .replaceAll('{wetz_oe_number}', (vendor_oe_number || ''))
        .replaceAll('{vendor_name_clear}', (vendor_name_clear || ''))
        .replaceAll('{vendor_oe_number}', (vendor_oe_number || ''))
        .replaceAll('{maker}', (maker || ''))
        .replaceAll('{model}', (model || ''))
        .replaceAll('{code}', (this.product.code || ''))
      ;
      return this.productSeoLocaleData.seo_title || title || this.productLocaleData.name;
    },
    seoDescription() {
      let descr = this.dbConfigSeoProductLocaleData.seo_descr_product;
      const vendor_name_clear = this.productLocaleData.name.split('(')[0]
      
      let vendor_oe_number = ''
      let maker = ''
      let model = ''
      let vehicle_type_name = ''
      if (this.$i18n.locale === 'ru') {
        if (process.env.VENDOR === 'wetz') {
          this.productLocaleData.name.indexOf(';') > -1 ? this.productLocaleData.name.split(';')[1].trim() || '' : ''
          vendor_oe_number = vendor_oe_number.replaceAll(')', '')
          maker = this.propMaker
          model = this.propModel
        }
        else if (process.env.VENDOR === 'wellfar') {
          const oems = this.getPropValues('OE-номер')
          if (oems?.length)
            vendor_oe_number = oems[0]
          const vehicle_types = this.getPropValues('Тип ТС')
          if (vehicle_types?.length === 1) {
            const vt = {
              'cv': 'коммерческой техники',
              'pc': 'легковых автомобилей',
            }
            vehicle_type_name = vt[vehicle_types[0]]
          }
          if (this.product.car_model_details?.length) {
            maker = this.product.car_model_details[0].model.mark.name
            const models = this.product.car_model_details.map(x => x.engine || x.power_kw + ' kW')
            model = models[0]
          }
        }
        if (descr) {
          if ((maker || model) && (descr?.indexOf('{maker}') !== -1 || descr?.indexOf('{model}') !== -1)) {
            descr = descr.replaceAll('{для}', ('для'))
          }
          else {
            descr = descr.replaceAll('{для}', (''))
          }
          if (vehicle_type_name && descr?.indexOf('{vehicle_type}') !== -1 ) {
            descr = descr.replaceAll('{для_vt}', ('для'))
          }
          else {
            descr = descr.replaceAll('{для_vt}', (''))
          }
        }      
      }

      descr = String(descr || '')
        .replaceAll('{name}', (this.productLocaleData.name || ''))
        .replaceAll('{wetz_name_clear}', (vendor_name_clear || ''))
        .replaceAll('{wetz_oe_numbers}', (this.propOE || ''))
        .replaceAll('{vendor_name_clear}', (vendor_name_clear || ''))
        .replaceAll('{vendor_oe_numbers}', (this.propOE || ''))
        .replaceAll('{vendor_oe_number}', (vendor_oe_number || ''))
        .replaceAll('{vehicle_type}', (vehicle_type_name || ''))
        .replaceAll('{maker}', (maker || ''))
        .replaceAll('{model}', (model || ''))
        .replaceAll('{code}', (this.product.code || ''))
      ;
      return this.productSeoLocaleData.seo_description || descr || '';
    },
    getImageUrl() {
      if (this.product.product_images.length) {
        return this.$options.filters.baseUrl(this.product.product_images[0].image.url);
      } else {
        return this.dbConfig.default_product_image;
      }
    },
    getCategory() {
      return this.product.categories[0].category_translations?.find(x => x.language.slug === this.$i18n.locale).name || {};
    },
    getBarcode() {
      let property = this.productProperties.find(x => x.name === 'Штрих-код');
      return this.product.product_properties?.find(
          x => x.property === property.id && x?.value && (!x.language || x.language.slug === this.$i18n.locale)
      )?.value || {};
    },
    getApplicability() {
      let manufacturer = this.productProperties.find(x => x.name === 'Производитель двигателя');
      manufacturer = this.product.product_properties?.find(
          x => x.property === manufacturer.id && x.value && (!x.language || x.language.slug === this.$i18n.locale))?.multiple_values[0] || '';
      let models = this.productProperties.find(x => x.name === 'Модель двигателя');
      models = this.product.product_properties?.find(
          x => x.property === models.id && x.value && (!x.language || x.language.slug === this.$i18n.locale))?.multiple_values || '';
      let applicability = [];
      if (models) {
        models.forEach((model) => applicability.push(manufacturer + ': ' + model));
      }
      return applicability.join('; ');
    },
    getModel() {
      let oe = this.productProperties.find(x => x.name === 'OE');
      oe = this.product.product_properties?.find(
          x => x.property === oe.id && x.value && (!x.language || x.language.slug === this.$i18n.locale))?.multiple_values[0] || '';
      let iam = this.productProperties.find(x => x.name === 'IAM');
      iam = this.product.product_properties?.find(
          x => x.property === iam.id && x.value && (!x.language || x.language.slug === this.$i18n.locale))?.multiple_values[0] || '';
      let model = [];
      model.push(this.product.code);
      if (oe) {
        model.push(oe);
      }
      if (iam) {
        model.push(iam);
      }
      return model.join(', ');
    },
    getQuantityInPackage() {
      let quantity = this.productProperties.find(x => x.name === 'Количество в упаковке , шт');
      return quantity = this.product.product_properties?.find(
          x => x.property === quantity.id && x.value && (!x.language || x.language.slug === this.$i18n.locale))?.multiple_values[0] || '';
    },
    schemaData() {
      return {
        "@context": "http://schema.org",
        '@type': 'Product',
        'category': this.getCategory,
        'name': this.productLocaleData.name,
        'image': this.getImageUrl,
        'brand': process.env.VENDOR.toUpperCase(),
        'manufacturer': process.env.VENDOR.toUpperCase(),
        'sku': this.product.code,
        'gtin': this.getBarcode,
        'gtin13': this.getBarcode,
        'description': this.seoDescription,
        'isAccessoryOrSparePartFor': this.getApplicability,
        'model': this.getModel,
        'itemCondition': 'http://schema.org/NewCondition',
        'offers': {
          '@type': 'Offer',
          "acceptedPaymentMethod":
              [
                {
                  "@type": "PaymentMethod",
                  "@id": "http://purl.org/goodrelations/v1#ByBankTransferInAdvance"
                },
                {
                  "@type": "PaymentMethod",
                  "@id": "http://purl.org/goodrelations/v1#ByInvoice"
                },
              ],
          'itemCondition': 'http://schema.org/UsedCondition',
          'availability': 'http://schema.org/InStock',
          'category': this.getCategory,
          'gtin13': this.getBarcode,
          'includesObject': this.getQuantityInPackage,
          'sku': this.product.code,
          'description': this.seoDescription,
          "areaServed": [{
            "@type": "City",
            "name": "Moscow",
            "sameAs": "https://en.wikipedia.org/wiki/Moscow"
          }],
          'priceCurrency': 'RUB',
          'price': this.product.price,
          'url': process.env.BASE_CLIENT_URL + this.$route.path,
        }
      }
    },
  },
}

export const whereToBuyPageMixin = {
  mixins: [seoMixin],
  computed: {
    dbConfigSeoPageLocaleData() {
      return this.dbConfig.seo_page_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    seoTitle() {
      let title = this.dbConfigSeoPageLocaleData.seo_title_where_to_buy_page;
      title = String(title || '');
      return title || '';
    },
    seoDescription() {
      let descr = this.dbConfigSeoPageLocaleData.seo_descr_where_to_buy_page;
      descr = String(descr || '');
      return descr || '';
    },
  },
}

export const whereToBuyCompanyPageMixin = {
  mixins: [seoMixin],
  computed: {
    dbConfigSeoPageLocaleData() {
      return this.dbConfig.seo_page_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
    seoTitle() {
      if (!this.company || !this.company.name) {
        return '';
      }
      let title = this.dbConfigSeoPageLocaleData.seo_title_where_to_buy_company_page;
      title = String(title || '');
      if (title && title.includes('{name}')) {
        title = title.replaceAll('{name}', this.company.name);
      }
      return title || '';
    },
    seoDescription() {
      if (!this.company || !this.company.name) {
        return '';
      }
      let descr = this.dbConfigSeoPageLocaleData.seo_descr_where_to_buy_company_page;
      descr = String(descr || '');
      if (descr && descr.includes('{name}')) {
        descr = descr.replaceAll('{name}', this.company.name);
      }
      return descr || '';
    },
  },
}
