import Model from './base';

export class DbConfig extends Model {
  resource() {
    return 'dbconfig';
  }

  defaultAttributes() {
    return {
      company_name: null,
      company_phone: null,
      company_email: null,
      company_address: null,
      whatsapp: null,
      chat_max_upload_file_size_mb: null,
      chat_upload_file_extensions: [],
      default_product_image: null,
      seo_category_translations: [],
      seo_page_translations: [],
      seo_product_translations: [],
      display_help_modal: null,
    }
  }
}
