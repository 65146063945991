import Model from './base';

export class Product extends Model {
  resource() {
    return 'catalog/product';
  }

  defaultAttributes() {
    return {
      is_favourite: false,
      visible: false,
      amount_free: {},
      code: null,
      price: null,
      categories: [],
      product_images: [],
      product_properties: [],
      slug: null,
      product_translations: [],
      product_seo_translations: [],
    }
  }
}

export class ProductProperty extends Model {
  resource() {
    return 'catalog/property';
  }

  defaultAttributes() {
    return {
      name: null,
      use_filter: false,
      visibility: null,
      multiple_values: false,
      search: false,
      sum_totals: false,
      sort_order: null,
      reduce_multiple_values: null,
      filters: [],
      property_translations: [],
    }
  }
}

export class ProductFavourite extends Model {
  resource() {
    return 'catalog/product-favourite';
  }

  defaultAttributes() {
    return {
      product: null,
      user: null,
    }
  }
}
