import Model from './base';

export class Language extends Model {
  resource() {
    return 'language';
  }

  defaultAttributes() {
    return {
      slug: null,
      name: null,
      sort_order: 0,
      active: true,
    }
  }
}
