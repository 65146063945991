export default ({ app, $auth }) => {
  $auth.onRedirect((to, from) => {
    return app.localePath(to);
  });
  let redirect = $auth.$storage.options.redirect;
  for (let key in redirect) {
    redirect[key] = app.localePath(redirect[key]);
  }
  $auth.$storage.options.redirect = redirect;
}
