import { Model as BaseModel } from 'vue-api-query';

export default class Model extends BaseModel {
  constructor(...attributes) {
    super(...attributes);
    if (attributes.length) {
      Object.assign(this, this.defaultAttributes(), ...attributes);
    }
  }

  stringifyOptions() {
    return {
      arrayFormat: 'brackets',
    }
  }

  baseURL() {
    return process.env.BASE_URL;
  }

  request(config) {
    if (config.url) {
      const parts = config.url.split(/\?(.*)/s);
      config.url = parts[0];
      if (!config.url.endsWith('/')) {
        config.url += '/';
      }
      if (parts.length > 1 && parts[1]) {
        config.url += `?${parts[1]}`;
      }
    }
    return this.$http.request(config);
  }

  defaultAttributes() {
    return {}
  }

  toJSON() {
    return {...this}
  }
}
